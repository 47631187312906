<template>
 <div class="fullscreen-dialog-container">
  <div class="dialog-container">
   <div class="dv-border-box-7"
    style="background-color: transparent; box-shadow: rgba(79, 120, 178, 0.5) 0px 0px 40px inset; border: 1px solid rgb(83, 129, 187);">

    <svg width="781" height="597" class="dv-border-svg-container">
     <polyline points="0, 10 0, 0 10, 0" class="dv-bb7-line-width-5" stroke="rgba(0, 120, 255,1)"></polyline>
    </svg>
    <div class="border-box-content">
     <div class="container">
      <div class="close" @click="$emit('close')">
       <img src="@/assets/icon/close.png">
      </div>
      <div class="score">
       {{ companyDetail.row.score }}
      </div>
      <div class="title">
       {{ companyDetail.row.compName }}
      </div>
      <section>
       <p>联系人: {{ companyDetail.row.linkman }}</p>
       <p>联系电话:  {{ companyDetail.row.phone }}</p>
       <p>需求分类:  {{ companyDetail.row.type }}</p>
       <p>需求内容:  {{ companyDetail.row.content }}</p>
       <p>注册日期: {{ (new Date(companyDetail.row.registTimestamp)).toLocaleDateString().replaceAll('/','-') }}</p>
       <p>统一社会信用代码: {{ companyDetail.row.identifierCode }}</p>
       <p>法定代表人: {{ companyDetail.row.fadingdaibiaoren }}</p>
       <p>经营范围: {{ companyDetail.row.jingyingfanwei }}</p>
       <p>年营收: {{ companyDetail.row.nianyingshou }}</p>
      </section>
     </div>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
export default {
 props: {
  companyDetail: {
   type: Object,
   default: {}
  }
 }
}
</script>

<style scoped lang="less">
.fullscreen-dialog-container {
 display: grid;
 place-items: center;
 height: 100%;
 width: 100%;
 background-color: rgba(0, 0, 0, 0.5);
 position: fixed;
 top: 0;
 left: 0;
 z-index: 1;
 color: white;
}

.dialog-container {
 width: 623px;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: center;
 position: relative;

 background-color: rgba(0, 0, 0, 0.5);
}

.container {
 width: 100%;
 height: 100%;
 position: relative;

 .close {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 44px;
  width: 44px;
  cursor: pointer;
  z-index: 1;

  img {
   width: 100%;
   height: 100%;
  }
 }

 .score {
  font-weight: bold;
  color: #F35252;
  font-size: 46px;
  position: absolute;
  right: 71px;
  top: 128px;
 }

 .title {
  font-size: 32px;
  padding-top: 50px;
  text-align: center;
  font-weight: bold;
 }

 section {
  margin-top: 54px;
  padding-left: 70px;
  padding-right: 111px;
  p{font-size: 20px;line-height: 30px;}
 }

}</style>