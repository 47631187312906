

<template>
 <div class="lun-bo-biao">
  <dv-border-box-11 title="最新需求信息" :titleWidth="250">
   <div class="lun-bo-biao-container">
    <div style="height:100%">
     <dv-scroll-board :config="lunbobiao" style="height:100%;width: 535px;" :class="{ usingDarkTheme }"
      @click="rowClick($event)" />
    </div>
   </div>
  </dv-border-box-11>
 </div>
</template>

<script>
export default {
 props: {
  usingDarkTheme: Boolean,
  dataByMap: Array
 },
 data() {
  return {
   lunbobiao: {
    headerBGC: "transparent",
    oddRowBGC: "transparent",
    evenRowBGC: "#052b5a",
    header: ['企业名称', '联系电话', '需求分类', '办理状态'],
    data: [

    ],
    index: false,
    columnWidth: [255, 100, 100, 80],
    rowNum: 8,
    align: new Array(4).fill('center')
   },
   orgData: [
    {
     compName: '瑞盟科技 (Ruimeng Technology)',
     phone: '123456789',
     type: '检验检测',
     state: '待回复',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '1.6'
    },
    {
     compName: '星璀集团 (Xingcui Group)',
     phone: '123456789',
     type: '认证认可',
     state: '待回复',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '3.3'
    },
    {
     compName: '天谷网络 (Tiangu Network)',
     phone: '123456789',
     type: '特种设备',
     state: '已完结',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '0.9'
    },
    {
     compName: '金翼创新 (Jinyi Innovation)',
     phone: '123456789',
     type: '校准检定',
     state: '待回复',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '3.1'
    },
    {
     compName: '和泽科技 (Heze Technology)',
     phone: '123456789',
     type: '认证认可',
     state: '已完结',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '2.8'
    },
    {
     compName: '蓝海控股 (Lanhai Holdings)',
     phone: '123456789',
     type: '校准检定',
     state: '待回复',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '1.7'
    },
    {
     compName: '飞鹰科技 (Feiying Technology)',
     phone: '123456789',
     type: '认证认可',
     state: '已完结',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '3.6'
    },
    {
     compName: '宇宙科技 (Yuzhou Technology)',
     phone: '123456789',
     type: '知识产权',
     state: '已完结',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '2.5'
    },
    {
     compName: '虹橙信息 (Hongcheng Information)',
     phone: '123456789',
     type: '特种设备',
     state: '待回复',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '2.3'
    },
    {
     compName: '豪迈集团 (Haomai Group)',
     phone: '123456789',
     type: '校准检定',
     state: '待回复',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '5.0'
    },
    {
     compName: '极光科技 (Jiguang Technology)',
     phone: '123456789',
     type: '校准检定',
     state: '已完结',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '2.5'
    },
    {
     compName: '雷霆软件 (Leiting Software)',
     phone: '123456789',
     type: '特种设备',
     state: '已完结',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '4.2'
    },
    {
     compName: '新辉能源 (Xinhui Energy)',
     phone: '123456789',
     type: '校准检定',
     state: '待回复',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '2.4'
    },
    {
     compName: '盛达投资 (Shengda Investment)',
     phone: '123456789',
     type: '知识产权',
     state: '已评价',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '2.7'
    },
    {
     compName: '雪峰科技 (Xuefeng Technology)',
     phone: '123456789',
     type: '校准检定',
     state: '待回复',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '0.8'
    },
    {
     compName: '天纵传媒 (Tianzong Media)',
     phone: '123456789',
     type: '知识产权',
     state: '已完结',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '1.6'
    },
    {
     compName: '光华网络 (Guanghua Network)',
     phone: '123456789',
     type: '校准检定',
     state: '待回复',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '3.4'
    },
    {
     compName: '蓝箭航天 (Lanjian Aerospace)',
     phone: '123456789',
     type: '知识产权',
     state: '待回复',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '4.7'
    },
    {
     compName: '星云科技 (Xingyun Technology)',
     phone: '123456789',
     type: '校准检定',
     state: '已完结',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '2.5'
    },
    {
     compName: '东方联合 (Dongfang Union)',
     phone: '123456789',
     type: '知识产权',
     state: '已完结',
     linkman: '小明',
     content: '柘城是河南省商丘市下辖县，位于河南省东部、睢阳区西南部，北与宁陵县、睢县相连，南与鹿邑县接壤，西与太康县相邻',
     score: '0.0'
    }
   ]
  }
 },
 mounted() {
   this.lunbobiao = { ...this.lunbobiao, evenRowBGC: this.usingDarkTheme ? "#052b5a" : "#0078ff" }
  this.renderData()
 },
 methods: {
  renderData() {
   let data = this.orgData.map(({ compName, phone, type, state, linkman, content, score }) => ([compName, phone, type, state]))
   this.lunbobiao = { ...this.lunbobiao, data }
  },
  rowClick(ev) {
   this.$emit('rowItemClick', { row: this.orgData[ev.rowIndex] })
  }
 },
 watch: {
  usingDarkTheme(val) {
   this.lunbobiao = { ...this.lunbobiao, evenRowBGC: val ? "#052b5a" : "#0078ff" }
  },
  dataByMap(val){
   this.orgData = val
   this.renderData()
  }
 }
}
</script>

<style scoped lang="less">
.lun-bo-biao {
 flex: 1;
}

.lun-bo-biao-container {
 box-sizing: border-box;
 padding: 70px 20px 30px 16px;
 height: 100%;
}

.usingDarkTheme {
 color: #009CFF;
}
</style>