

<template>
 <div class="lun-bo-biao">
  <dv-border-box-11 title="最新需求信息" :titleWidth="250">
   <div class="lun-bo-biao-container">
    <div style="height:100%">
     <dv-scroll-board :config="lunbobiao" style="height:100%;width: 535px;" :class="{ usingDarkTheme }"
      @click="rowClick($event)" />
    </div>
   </div>
  </dv-border-box-11>
 </div>
</template>

<script>
export default {
 props: {
  usingDarkTheme: Boolean,
  dataByMap: Array
 },
 data() {
  return {
   lunbobiao: {
    headerBGC: "transparent",
    oddRowBGC: "transparent",
    evenRowBGC: "#052b5a",
    header: ['企业名称', '联系电话', '需求分类', '办理状态'],
    data: [

    ],
    index: false,
    columnWidth: [255, 100, 100, 80],
    rowNum: 8,
    align: new Array(4).fill('center')
   },
   orgData:[],
  }
 },
 mounted() {
   this.lunbobiao = { ...this.lunbobiao, evenRowBGC: this.usingDarkTheme ? "#052b5a" : "#0078ff" }
  this.renderData()
 },
 methods: {
  renderData() {
   let data = this.orgData.map(({ compName, phone, type, state, linkman, content, score }) => ([compName, phone, type, state]))
   this.lunbobiao = { ...this.lunbobiao, data }
  },
  rowClick(ev) {
   this.$emit('rowItemClick', { row: this.orgData[ev.rowIndex] })
  }
 },
 watch: {
  usingDarkTheme(val) {
   this.lunbobiao = { ...this.lunbobiao, evenRowBGC: val ? "#052b5a" : "#0078ff" }
  },
  dataByMap(val){
   if(!val) return;
   this.orgData = val
   this.renderData()
  }
 }
}
</script>

<style scoped lang="less">
.lun-bo-biao {
 margin-top: 30px;
 flex: 1;
}

.lun-bo-biao-container {
 box-sizing: border-box;
 padding: 70px 20px 30px 16px;
 height: 100%;
}

.usingDarkTheme {
 color: #009CFF;
}
</style>