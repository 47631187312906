<template>
 <div class="zhe-xian-tong-ji">
  <dv-border-box-12>
   <div style="display: flex;align-items: center; height: 100%;">
    <div ref="zhe_xian_tong_ji_echarts" style="width:100%;height:90%;"></div>
   </div>
  </dv-border-box-12>
 </div>
</template>

<script>
export default {
 data() {
  return {
   zhexiantongji: {
    xAxis: {
     type: 'category',
     boundaryGap: false,
     data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
     axisLabel: {
      color: '#ffffff'
     }
    },
    legend: {
     data: ['注册', '访问', '订单'],
     textStyle: {
      color: '#ffffff'
     }

    },
    grid: {
     top: '20%',
     left: '3%',
     right: '8%',
     bottom: '3%',
     containLabel: true
    },
    yAxis: {
     type: 'value',
     axisLabel: {
      color: '#ffffff'
     }
    },
    series: [
     {
      name: '注册',
      type: 'line',
      smooth: true,
      data: [120, 132, 101, 134, 90, 230, 210],
        stack: '注册',
      label: {
       show: true,
       position: 'top',
       color:"white"
      }
     },
     {
      name: '访问',
      type: 'line',
      smooth: true,
      data: [220, 182, 191, 234, 290, 330, 310],
        stack: '访问',
      label: {
       show: true,
       position: 'top',
       color:"white"
      }
     },
     {
      name: '订单',
      type: 'line',
      smooth: true,
      data: [50, 28, 289, 200, 152, 145, 325],
        stack: '订单',
      label: {
       show: true,
       position: 'top',
       color:"white"
      }
     },
    ]
   }
  }
 },
 mounted() {
  setTimeout(() => {
   var zhe_xian_tong_ji_echarts = this.$echarts.init(this.$refs.zhe_xian_tong_ji_echarts);
   zhe_xian_tong_ji_echarts.setOption(this.zhexiantongji)
  })
 },
}
</script>

<style scoped lang="less">
.zhe-xian-tong-ji {
 height: 271px;
}
</style>