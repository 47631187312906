<template>
 <div class="xu-qiu-lei-bie">
  <dv-border-box-11 title="质量服务需求类别统计" :titleWidth="250">
   <div class="xu-qiu-lei-bie-container" ref="xu_qiu_lei_bie_echarts"></div>
  </dv-border-box-11>
 </div>
</template>

<script>
export default {
 data() {
  return {
  }
 },
 props: {
  dataByMap: Array
 },
 watch: {
  dataByMap(val) { this.renderechart(val) }
 },
 mounted() {
  this.initechart()
  this.renderechart(this.dataByMap)
  this.myChart.on('click',  (ev)=> {
   // console.log(ev.name, ev.data)
   this.$emit("columnclick", ev.name, ev.data)
  })
 },
 methods: {
  initechart() {
   this.myChart = this.$echarts.init(this.$refs.xu_qiu_lei_bie_echarts)
  },
  renderechart(data) {
   let option = {
    /*
       title:{
        text:"质量服务需求类别统计",
        textStyle:{
         color:"#fff",
         fontSize:24,
        },
        top:"3%",
        left:"50%",
         textAlign :"center"
       },
       */
    xAxis: {
     type: 'category',
     data: ['需求总量', '需求企业', '检验检测', '校准检定', '认证认可', '知识产权'],
     axisLabel: {
      color: '#ffffff'
     },
    },
    yAxis: {
     type: 'value',
     axisLabel: {
      color: '#ffffff'
     },
    },
    series: [
     {
      data,
      type: 'bar',
      itemStyle: {
       // 渐变色设置
       color: new this.$echarts.graphic.LinearGradient(
        0, 0, 0, 1,
        [
         { offset: 0, color: '#00f3ff' },  // 渐变起始颜色
         { offset: 1, color: '#007cf5' }   // 渐变结束颜色
        ]
       )
      },
     }
    ]
   }
   this.myChart.setOption(option)
  }
 }
}
</script>

<style scoped lang="less">
.xu-qiu-lei-bie {
 flex: 1;
 width: 100%;
}

.xu-qiu-lei-bie-container {
 width: 100%;
 height: 100%;
}
</style>