<template>
 <div class="jiao-nang-zhu-zhuang-tu">

  <dv-border-box-11 title="质量服务需求调查问卷" :titleWidth="250">

   <div ref="jiao_nang_zhu_zhuang_tu_echarts" style="width:100%;height:90%;" @click="$emit('click')"></div>
  </dv-border-box-11>
 </div>
</template>

<script>
const colors = [
 "#8b2ff3",
 "#e3bb1c",
 "#33d1ee",
 "#0078ff",
 "#2eed95",
]
export default {
 data() {
  return {
   jiaonangzhuzhuangtu: {
    xAxis: {
     show: false,
    },
    yAxis: {
     type: 'category',
     data: ['检验检测', '认证认可', '校准检定', '特种设备', '知识产权'],
     axisLabel: {
      color: '#ffffff'
     },
     axisLine: {
      show: false,
     },
     axisTick: {
      show: false,
     }
    },
    grid: {
     top: '30%',
     left: '5%',
     right: '8%',
     bottom: '0%',
     containLabel: true
    },
    series: [
     {
      type: 'bar',
      barWidth: 20,  // 设置柱子的具体宽度，单位为像素
      data: [120, 200, 150, 80, 70, 110],
      itemStyle: {
       borderRadius: 200,  // 设置圆角
       color: function (params) {
        // 获取对应数据项的颜色值
        return colors[params.dataIndex]
       },
      },
      label: {
       show: true,
       position: 'right',
       color: "white"
      }
     }
    ]
   }
  }
 },
 props: {
  dataByMap: Array
 },
 mounted() {
  setTimeout(() => {
    this.jiaonangzhuzhuangtu.series[0].data = this.dataByMap
   var jiao_nang_zhu_zhuang_tu_echarts = this.$echarts.init(this.$refs.jiao_nang_zhu_zhuang_tu_echarts);
   jiao_nang_zhu_zhuang_tu_echarts.setOption(this.jiaonangzhuzhuangtu)
   this.$watch('dataByMap', (newVal) => {
    this.jiaonangzhuzhuangtu.series[0].data = newVal
    jiao_nang_zhu_zhuang_tu_echarts.setOption(this.jiaonangzhuzhuangtu)
   })
  })
 },
}
</script>

<style scoped lang="less">
.jiao-nang-zhu-zhuang-tu {
 height: 271px;
 padding-bottom: 10px;
}
</style>