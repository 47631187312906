<template>
 <div id="he-plugin-simple"></div>
</template>

<script>
export default {
 data() {
  return {

  }
 },
 mounted() {

 }
}
</script>

<style scoped lang="less"></style>

