<template>
 <div class="xu-qiu-lei-bie">
  <dv-border-box-11   title="按市场主体类型分类的质量服务需求量（单位：个）" :titleWidth="480">
   <div class="xu-qiu-lei-bie-container" ref="xu_qiu_lei_bie_echarts"></div>
  </dv-border-box-11>
 </div>
</template>

<script>
const colors = [
 "#8b2ff3",
 "#e3bb1c",
 "#33d1ee",
 "#0078ff",
 "#2eed95",
]
export default {
 data() {
  return {
  }
 },
 props: {
  dataByMap: Array
 },
 watch: {
  dataByMap(val) { this.renderechart(val) }
 },
 mounted() {
  this.initechart()
  this.renderechart(this.dataByMap)
  
  this.myChart.on('click',  (ev)=> {
   // console.log(ev.name, ev.data)
   this.$emit("columnclick", ev.name, ev.data)
  })
 },
 methods: {
  initechart() {
   this.myChart = this.$echarts.init(this.$refs.xu_qiu_lei_bie_echarts)
  },
  renderechart(data) {

     let option = {
  /*
   title: {
    text: "按市场主体类型分类的质量服务需求量（单位：个）",
    textStyle: {
     color: "#fff",
     fontSize: 20,
    },
    top: "3%",
    left: "50%",
    textAlign: "center"
   },
   */
   yAxis: {
    type: 'category',
    data: [ '个体工商户', '国有企业', '外商投资企业', '联营企业', '私营企业' ],
    axisLabel: {
     color: '#ffffff'
    },
     axisLine:{
      show: false,
     },
     axisTick:{
      show: false,
     }
   },
   xAxis: {
    type: 'value',
    axisLabel: {
     color: '#ffffff'
    },
    show:false,
   },
   grid: {
    left: "20%",
    right: "10%",
    bottom: "3%",

   },
   series: [
    {
     data,
     type: 'bar',
    barWidth: 30, // 设置柱状图的宽度，可以根据需要进行调整
      showBackground: true,
     itemStyle: {
      // 渐变色设置
      color: p=>colors[p.dataIndex]
     }, 
     label: {
      // 显示柱状图上的数字
      show: true,
      position: 'right',
      color: '#ffffff'  // 将标签颜色设置为白色
      
     },
    }
   ]
  }
   this.myChart.setOption(option)
  }
 }
}
</script>

<style scoped lang="less">
.xu-qiu-lei-bie {
 flex: 1;
 width: 100%;
}

.xu-qiu-lei-bie-container {
 width: 100%;
 height: 100%;
}
</style>