<template>
 <div class="fullscreen-dialog-container">
  <div class="dialog-container">
   <div class="dv-border-box-7"
    style="background-color: transparent; box-shadow: rgba(79, 120, 178, 0.5) 0px 0px 40px inset; border: 1px solid rgb(83, 129, 187);">

    <svg width="781" height="597" class="dv-border-svg-container">
     <polyline points="0, 10 0, 0 10, 0" class="dv-bb7-line-width-5" stroke="rgba(0, 120, 255,1)"></polyline>
     <polyline points="771, 0 781, 0 781, 10" class="dv-bb7-line-width-5" stroke="rgba(0, 120, 255,1)"></polyline>
     <polyline points="771, 597 781, 597 781, 587" class="dv-bb7-line-width-5" stroke="rgba(0, 120, 255,1)"></polyline>
     <polyline points="0, 587 0, 597 10, 597" class="dv-bb7-line-width-5" stroke="rgba(0, 120, 255,1)"></polyline>
    </svg>
    <div class="border-box-content">
     <div class="container">
      <div class="close" @click="$emit('close')">
       <img src="@/assets/icon/close.png">
      </div>
      <div class="table-container">
       <!-- <dv-scroll-board :config="lunbobiao" style="height:100%;width: 535px;" :class="{ usingDarkTheme }" />        -->


       <div data-v-95bad154="" class="dv-scroll-board" style="height: 100%; width: 535px;">
        <div class="header" style="background-color: rgb(5, 43, 90);">
         <div align="center" class="header-item" style="height: 35px; line-height: 35px; width: 200px;">用户名</div>
         <div align="center" class="header-item" style="height: 35px; line-height: 35px; width: 120px;">手机号</div>
         <div align="center" class="header-item" style="height: 35px; line-height: 35px; width: 130px;">注册时间</div>
        </div>
        <div class="rows" style="height: 347px;">
         <div class="row-item" style="height: 43.375px; line-height: 43.375px; background-color: transparent;"
          v-for="item in lunbobiao.data">
          <div align="center" class="ceil" style="width: 200px;">{{ item[0] }}</div>
          <div align="center" class="ceil" style="width: 120px;">{{ item[1] }}</div>
          <div align="center" class="ceil" style="width: 130px;">{{ item[2] }}</div>
         </div>
        </div>
       </div>





      </div>
     </div>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
export default {
 props: {
  usingDarkTheme: {
   type: Boolean,
   default: false
  }
 },
 data() {

  return {

   lunbobiao: {
    headerBGC: "#052b5a",
    oddRowBGC: "transparent",
    evenRowBGC: "transparent",
    header: ['用户名', '手机号', '注册时间'],
    data: [
     ["小玲", "15155151511", "2020-01-01"],
     ["小健", "15511115551", "2020-01-01"],
     ["小婷", "15515115511", "2020-01-01"],
     ["小杰", "15515115511", "2020-01-01"],
     ["小悦", "15155511511", "2020-01-01"],
     ["小宇", "15555111151", "2020-01-01"],
     ["小娜", "11115555511", "2020-01-01"],
     ["小磊", "15551115511", "2020-01-01"],
    ],
    index: false,
    columnWidth: [200, 120, 130],
    rowNum: 8,
    align: new Array(4).fill('center')
   }

  }
 }
}
</script>

<style scoped lang="less">
.fullscreen-dialog-container {
 display: grid;
 place-items: center;
 height: 100%;
 width: 100%;
 background-color: rgba(0, 0, 0, 0.5);
 position: fixed;
 top: 0;
 left: 0;
 z-index: 1;
 color: white;
}

.dialog-container {
 height: 480px;
 width: 623px;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: center;
 position: relative;

 background-color: rgba(0, 0, 0, 0.5);
}

.container {
 width: 100%;
 height: 100%;
 position: relative;

 .close {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 44px;
  width: 44px;
  cursor: pointer;
  z-index: 1;

  img {
   width: 100%;
   height: 100%;
  }
 }

 .table-container {
  padding-top: 50px;
  padding-left: 50px;
  height: 80%;
  width: 80%;

  .usingDarkTheme {
   color: #009CFF;
  }
 }

}
</style>