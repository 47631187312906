<template>
 <div class="bing-zhuang-tu-container">
  <dv-border-box-11 title="行业统计" :titleWidth="150">

   <div class="chart-container">
    <div class="chart" ref="bing_zhuang_tu_echarts"></div>
   </div>
  </dv-border-box-11>
 </div>
</template>

<script>
export default {
 data() {
  return {
   highLightCacheName: ""
  }
 },
 props: {
  dataByMap: Array
 },
 watch: {
  dataByMap(val) { this.renderechart(val) }
 },
 mounted() {
  this.initechart()
  this.renderechart(this.dataByMap)
  this.myChart.on("click", ({ data }) => {
   let { name, targetList } = data
   if (this.highLightCacheName === name) return;
   this.highLightCacheName = name
   this.$emit("fanclick", targetList)
   this.clamdown()
   this.myChart.dispatchAction({
    type: 'highlight',
    seriesIndex: 0,
    name
   });
  })


 },
 methods: {
  initechart() {
   this.myChart = this.$echarts.init(this.$refs.bing_zhuang_tu_echarts)
  },
  renderechart(data) {
   let option = {
    tooltip: {
     trigger: 'item'
    },
    legend: {
     right: '5%',
     top: 'center',
     orient: 'vertical',
     textStyle: {
      color: "#fff",
      fontSize: 16
     },
     itemWidth: 20, // 设置图例项的宽度
     itemHeight: 15, // 设置图例项的高度
    },
    series: [
     {
      name: '详情信息',
      type: 'pie',
      left: '-25%',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,

      label: {
       show: true,
       formatter: ({ name, value }) => `${name} \n ${(value / (data.map(e => e.value).reduce((a, b) => a + b)) * 100).toFixed(2)}%`,
       position: 'inside',
       color: "white"
      },
      emphasis: {
       label: {
        show: true,
        fontSize: 25,
        fontWeight: 'bold',
        color: "#fff"
       }
      },
      labelLine: {
       show: false
      },
      data
     }
    ]
   }
   this.myChart.setOption(option)
  },
  clamdown() {
   this.highLightCacheName = ""
   this.myChart.dispatchAction({
    type: 'downplay',
   });
  }
 }
}
</script>

<style scoped lang="less">
.bing-zhuang-tu-container {
 flex: 1;
 margin-top: 40px;

 .chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .chart {
   width: 100%;
   height: 80%;
  }
 }
}
</style>