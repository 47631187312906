import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)

//  引入Echarts
import * as echarts from 'echarts'
import 'echarts-wordcloud';
Vue.prototype.$echarts = echarts

import VueRouter from 'vue-router'

Vue.use(VueRouter)

import router from './router/index.js'

new Vue({
  router,
  render: function (h) { return h(App) },
}).$mount('#app')
