

<template>
 <div class="fullscreen-dialog-container">
  <div class="dialog-container">

   <div class="dv-border-box-7"
    style="background-color: transparent; box-shadow: rgba(79, 120, 178, 0.5) 0px 0px 40px inset; border: 1px solid rgb(83, 129, 187);">

    <svg width="781" height="597" class="dv-border-svg-container">
     <polyline points="0, 10 0, 0 10, 0" class="dv-bb7-line-width-5" stroke="rgba(0, 120, 255,1)"></polyline>
     <polyline points="771, 0 781, 0 781, 10" class="dv-bb7-line-width-5" stroke="rgba(0, 120, 255,1)"></polyline>
     <polyline points="771, 597 781, 597 781, 587" class="dv-bb7-line-width-5" stroke="rgba(0, 120, 255,1)"></polyline>
     <polyline points="0, 587 0, 597 10, 597" class="dv-bb7-line-width-5" stroke="rgba(0, 120, 255,1)"></polyline>
    </svg>
    <div class="border-box-content">
     <div class="container">
      <div class="close" @click="$emit('close')">
       <img src="@/assets/icon/close.png">
      </div>
      <dv-scroll-board :config="config"
       style="width:500px;height:300px;position: absolute; top:50%;left:50%;transform: translate(-50%,-50%);"
       @click="rowItemClick" />
     </div>
    </div>
   </div>

   
   <div class="dv-border-box-7 company-detail"   v-if="Object.keys(companyDetail).length">

    <svg width="781" height="597" class="dv-border-svg-container">
     <polyline points="0, 10 0, 0 10, 0" class="dv-bb7-line-width-5" stroke="rgba(0, 120, 255,1)"></polyline>
     <polyline points="771, 0 781, 0 781, 10" class="dv-bb7-line-width-5" stroke="rgba(0, 120, 255,1)"></polyline>
     <polyline points="771, 597 781, 597 781, 587" class="dv-bb7-line-width-5" stroke="rgba(0, 120, 255,1)"></polyline>
     <polyline points="0, 587 0, 597 10, 597" class="dv-bb7-line-width-5" stroke="rgba(0, 120, 255,1)"></polyline>
    </svg>
    <div class="border-box-content">
   <div class=" container">
    
      <div class="score">
       {{ companyDetail.score }}
      </div>
      <div class="title">
       {{ companyDetail.compName }}
      </div>
      <section>
       <p>联系人: {{ companyDetail.linkman }}</p>
       <p>联系电话:  {{ companyDetail.phone }}</p>
       <p>需求分类:  {{ companyDetail.type }}</p>
       <p>需求内容:  {{ companyDetail.content }}</p>
       <p>办理状态: {{ companyDetail.state }}</p>
      </section>
   </div>
   </div>
   </div>
  </div>

 </div>
</template>

<script>
export default {
 props: {
  companyList: {
   type: Array,
  }
 },
 data() {
  return {
  companyDetail:{},
   config: {

    headerBGC: "transparent",
    oddRowBGC: "transparent",
    evenRowBGC: "#052b5a",
    header: ['企业名称', '联系电话', '需求分类', '办理状态'],
    data: [

    ],
    index: false,
    columnWidth: [200, 100, 100, 80],
    rowNum: 8,
    align: new Array(4).fill('center')
   }
  }
 },
 mounted() {
  this.config = { ...this.config, data: this.companyList.map(({ compName, phone, type, state, linkman, content, score }) => ([compName, phone, type, state])) }
 },
 methods: {

  rowItemClick({ row }) {
    this.companyDetail=this.companyList.filter(i => i.compName === row[0])[0]
  }
 }

}
</script>

<style scoped lang="less">
.fullscreen-dialog-container {
 display: grid;
 place-items: center;
 height: 100%;
 width: 100%;
 background-color: rgba(0, 0, 0, 0.5);
 position: fixed;
 top: 0;
 left: 0;
 z-index: 1;
 color: white;
}

.dialog-container {
 height: 480px;
 width: 623px;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: center;
 position: relative;

 background-color: rgba(0, 0, 0, 0.5);
}

.container {
 width: 100%;
 height: 100%;
 position: relative;

 .close {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 44px;
  width: 44px;
  cursor: pointer;
  z-index: 1;

  img {
   width: 100%;
   height: 100%;
  }
 }

 .score {
  font-weight: bold;
  color: #F35252;
  font-size: 46px;
  position: absolute;
  right: 71px;
  top: 128px;
 }

 .title {
  font-size: 32px;
  padding-top: 50px;
  text-align: center;
  font-weight: bold;
 }

 section {
  margin-top: 54px;
  padding-left: 70px;
  padding-right: 111px;

  p {
   font-size: 20px;
   line-height: 30px;
  }
 }

}


.company-detail {
 position: absolute;
 right: 0;
 width: 500px;
 
 transform: translateX(calc(100% + 10px));
 background-color: rgba(0, 0, 0, 0.5);height: unset;box-shadow: rgba(79, 120, 178, 0.5) 0px 0px 40px inset; border: 1px solid rgb(83, 129, 187);
}
</style>