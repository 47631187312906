<template>
 <header>
  <div class="left" @click="$emit('changeTheme')" title="点击切换主题">质量基础设施一站式服务平台数据大屏</div>
  <div class="right">
 
 <div class="tian-qi-shi-jian">
  <TheWeather></TheWeather>
  <!-- 
  <div class="tian-qi">
   <img src="@/assets/icon/cloudy.png" style="width:31px;">
   <div class="tian-qi-state">
    <div>20-28℃</div>
    <div>多云</div>
   </div>
  </div>
   -->
  <div class="divider-line"></div>
  <div class="shi-jian">
   <div class="shi-fen-miao">
    {{ currentTime }}
   </div>
   <div class="ri-qi">
    <div class="xing-qi"> 星期{{ currentWeek }}</div>
    <div class="nian-yue-ri">{{ currentDate }}</div>
   </div>

   <div class="goback" v-if="showGoBack">
    <router-link to="/index" tag="div" style="display:flex;align-items: center;height:100%">
    <img src="@/assets/icon/home.png" alt="">
    </router-link>
   </div>
  </div>
 </div>

  </div>
 </header>
</template>

<script>
import TheWeather from "./TheWeather.vue"
export default {
 name: 'TheHeader',
 props: {
  showGoBack: {
   type: Boolean,
   default: true
  }
 },
 data() {
  return {
   currentTime: "获取中...",
   currentWeek: "获取中...",
   currentDate: "获取中...",
  }
 },
 components: {
  TheWeather
 },
 mounted() {
  setInterval(() => {
   this.currentTime = new Date().toLocaleTimeString()
   const weekdays = ['日', '一', '二', '三', '四', '五', '六'];  // 星期对应的中文

   const today = new Date();
   const dayOfWeek = today.getDay();
   this.currentWeek = weekdays[dayOfWeek];
   this.currentDate = new Date().toLocaleDateString().split("-").map(e => e.padStart(2, '0')).join("-")


  }, 1000);
 }
 }
</script>

<style scoped lang="less">
header {
 background-image: url("@/assets/images/head-bg.png");
 background-repeat: no-repeat;
 background-size: 100% 100%;
 width: 100%;
 height: 118px;
 display: flex;
 justify-content: space-between;
 position: relative;
 z-index: 1;

 .left {
 cursor: pointer;
    text-shadow: 0px 2px 1px rgba(7, 126, 96, 0.39);
    background: linear-gradient(180deg, #288DFF 0%, white 100%);
    margin-top: 20px;
    margin-left: 50%;
    transform: translate(-50%);
    white-space: nowrap;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px;
 }
 .right{
  .tian-qi-shi-jian {
  transform: translateY(40px);
 height: 100%;
 padding-right: 66px;
 display: flex;
 align-items: center;
 color: white;

 .tian-qi {
  display: flex;
  align-items: center;

  .tian-qi-state {
   margin-left: 10px;
   display: flex;
   flex-direction: column;
   font-size: 14px;
  }
 }

 .divider-line {
  width: 1px;
  height: 45px;
  background: #27A29D;
  opacity: 0.36;
  margin-left: 18px;
  margin-right: 15px;
 }

 .shi-jian {
  display: flex;
  align-items: center;

  .shi-fen-miao {
   font-size: 30px;
   white-space: nowrap;
  }

  .ri-qi {
   margin-left: 10px;

   font-size: 12px;
   color: white;
   opacity: .5;
   
   .xing-qi,.nian-yue-ri{
   white-space: nowrap;
   
   }

  }

  .goback {
   margin-left: 24px;
   cursor: pointer;
   height: 40px;
   line-height: 40px;
  }
 }
}
 }
}
</style>