<template>
 <div class="zhe-xian-tong-ji">
  <dv-border-box-11 title="需求走势图" :titleWidth="160">

   <div style="display: flex;align-items: center; height: 100%;">
    <div ref="zhe_xian_tong_ji_echarts" style="width:100%;height:90%;"></div>
   </div>
  </dv-border-box-11>
 </div>
</template>

<script>
export default {
 data() {
  return {
  }
 },
 props: {
  dataByMap: Array
 },
 watch: {
  dataByMap(val) { this.renderechart(val) }
 },
 mounted() {
  this.initechart()
  this.renderechart(this.dataByMap)
 },
 methods: {
  initechart() {
   this.myChart = this.$echarts.init(this.$refs.zhe_xian_tong_ji_echarts)
  },
  renderechart(data) {

   let option = {
    xAxis: {
     type: 'category',
     boundaryGap: false,
     data: ['1', '2', '3', '4', '5', '6'],
     axisLabel: {
      color: '#ffffff',
      formatter: '{value} 月' // 在标签中添加单位
     }
    },
    grid: {
     top: '20%',
     left: '3%',
     right: '8%',
     bottom: '3%',
     containLabel: true
    },
    yAxis: {
     type: 'value',
     axisLabel: {
      color: '#ffffff'
     }
    },
    series: [
     {
      data,
      type: 'line',
      smooth: true,
      itemStyle: {
       color: 'gold'
      },
      label: {
       show: true,
       position: 'top',
       color: "white"
      }
     }
    ]
   }
   this.myChart.setOption(option)
  }
 }
}
</script>

<style scoped lang="less">
.zhe-xian-tong-ji {
 height: 412px;
}
</style>