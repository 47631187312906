import VueRouter from 'vue-router'


import IndexPage from '@/pages/index/index.vue'
import DataPage from '@/pages/data/index.vue'
import DetailPage from '@/pages/detail/index.vue'

// 2. 定义路由
// 每个路由应该映射一个组件。 其中"component" 可以是
// 通过 Vue.extend() 创建的组件构造器，
// 或者，只是一个组件配置对象。
// 我们晚点再讨论嵌套路由。
export const routes = [
  { path: '/', component: IndexPage },
  { path: '/index', component: IndexPage } ,
  { path: '/data', component: DataPage } ,
  { path: '/detail', component: DetailPage } ,
]

const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})
export default router