<template>
 <div class="fei-xian-tu">
  <!-- <dv-border-box-12> -->
  <div class="map-container" @mouseover="mapHover" @mouseout="hovering = false">
   <div class="back" @click="back" v-show="canBack">
    <img src="@/assets/icon/back.png" alt="">
   </div>
   <div ref="map" style="width:100%;height:100%;"></div>
  </div>
  <!-- <dv-flyline-chart-enhanced :config="config" style="width:100%;height:100%;" /> -->
  <!-- </dv-border-box-12> -->
 </div>
</template>

<script>

export default {
 props: {
  hideTip: Boolean,
  default: false
 },
 data() {
  return {
   myChart: null,
   canBack: false,
   hovering: false,
   toolTipData: [],
   readyToolTipData: {
   },
   showingJSON: null
  }
 },
 mounted() {

  this.myChart = this.$echarts.init(this.$refs.map) // 初始化图表

  let areacode = sessionStorage.getItem("showingAreacode") || "100000"
  fetch("mockdata/" + areacode + ".json").then(e => e.json()).then(json => this.registerMapAndData(areacode, json))

  let current = 0
  setInterval(() => {
   let max = this.toolTipData.length
   if (this.canBack || this.hovering || this.hideTip) return;
   current++
   if (current == max + 1) current = 0
   this.clamdown()
   this.readyToolTipData = this.toolTipData[current]
   this.myChart.dispatchAction({
    type: 'highlight',
    seriesIndex: 0,
    dataIndex: current,
   })
   this.myChart.dispatchAction({
    type: 'showTip',
    seriesIndex: 0,
    dataIndex: current,
    position: 'top'
   })
  }, 2000)

 },
 methods: {
  registerMapAndData(areacode, json) {
   if (areacode == "100000") {
    this.canBack = false
   } else {
    this.canBack = true
   }
   this.clamdown()

   this.showingJSON = json
   this.toolTipData = this.showingJSON.features.map((item) => ({
    name: item.properties.name
   }))

   this.toolTipData = this.toolTipData.map((item, index) => ({
    ...item,
    compNum: index + 1, demandNum: index + 1, zuixinriqi: '7月' + (index + 1) + '日'
   }))

   this.$echarts.registerMap(areacode, this.showingJSON) // 生成矢量数据地图 第一个值(地图名称) 第二个值(geoJson格式的数据)

   this.changeMap(areacode)
   this.$emit("mapchange", this.showingJSON)
   this.myChart.on("click", ({ name }) => {
    let areacode = this.showingJSON.features.filter(e => e.properties.name == name)[0].properties.adcode.toString()
    fetch("mockdata/" + areacode + ".json").then(e => e.json()).then(json => this.registerMapAndData(areacode, json))

   })

  },
  changeMap(mapName) {
   sessionStorage.setItem("showingAreacode", mapName)
   const option = {
    visualMap: { // 左下角选峰值的小插件(视觉映射组件)
     show: false, // 是否显示视觉映射组件
     min: 0, // 最小值
     max: 100, // 最大值
     text: ['最大值', '最小值'], // 上下两端的文本内容
     textStyle: {
      color: '#fff' // 文字的颜色
     },
     realtime: false, // 拖拽时，是否实时更新
     calculable: true, // 是否显示拖拽用的手柄
     inRange: {
      // color: ['#00bbf2', '#f59557', '#f86505'] // 图元的颜色 - 注：使用颜色梯度时启用
     }
    },
    tooltip: { // 鼠标移入后展示数据
     show: true, // 是否显示提示框组件
     trigger: 'item', // item.数据项图形触发(主要在散点图) axis.坐标轴触发(主要在柱状图) none.什么都不触发
     triggerOn: 'mousemove', // 提示框触发的条件 mousemove.鼠标移动时触发 click.鼠标点击时触发 mousemove|click.同时鼠标移动和点击时触发 none.什么都不触发
     textStyle: {
      // fontSize: 20 // 提示框字体大小
     },
     // formatter: '{b}<br/>{c} 例', // 提示框浮层内容格式器
     formatter: (params) => {
      this.readyToolTipData = this.toolTipData.filter(e => e.name == params.name)[0]
      return [
       ' 企业数量：' + this.readyToolTipData.compNum,
       '需求数量：' + this.readyToolTipData.demandNum,
       '最新需求：' + this.readyToolTipData.zuixinriqi,
      ].join('<br>');
     }
    },
    series: [ // 地图参数
     {
      type: 'map', // 地图类型
      map: mapName, // 对应 registerMap 中的 mapName 名称
      roam: false, // 是否开启平滑缩放
      zoom: 1, // 当前视角内的缩放比例
      layoutCenter: ['50%', '50%'], // 地图中心在屏幕中的位置
      layoutSize: '100%', // 地图的大小(支持相对于屏幕宽高的百分比或者绝对的像素大小)
      label: { // 图形上的文本标签
       show: true, // 显示文本标签
       color: '#fff' // 字体颜色
      },
      itemStyle: {
       borderWidth: 2, // 图形的描边线宽
       borderColor: '#3bb3ff', // 图形的描边颜色
       color: '#1083e2', // 地图区域的颜色(背景颜色)
       label: { // 图形上的文本标签
        show: true, // 显示文本标签
        color: '#fff' // 字体颜色
       }
      },
      emphasis: { // 鼠标移入后的样式
       borderWidth: 2, // 图形的描边线宽
       borderColor: '#fff', // 图形的描边颜色
       areaColor: '#ed803b', // 地图区域的颜色(背景颜色) none.无样式
       label: { // 图形上的文本标签
        show: true, // 显示文本标签
        color: '#fff' // 字体颜色
       }
      },
      select: { // 地图选中区域样式
       itemStyle: {
        borderWidth: 2, // 图形的描边线宽
        borderColor: '#3bb3ff', // 图形的描边颜色
        areaColor: '#1083e2' // 地图区域的颜色(背景颜色)
       },
       label: { // 图形上的文本标签
        show: true, // 显示文本标签
        color: '#fff' // 字体颜色
       }
      },
      // data: this.areaList // 数据内容数组
     }
    ]
   }
   this.myChart.setOption(option) // 设置配置项和数据并显示图表
  },
  mapHover() {
   this.hovering = true
   this.canBack || this.clamdown()
  },
  clamdown() {
   this.myChart.dispatchAction({
    type: 'downplay',
   })
   this.myChart.dispatchAction({
    type: 'hideTip',
   })
  },
  back() {
   let path = this.showingJSON.features[0].properties.acroutes
   let areacode = path[path.length - 2]
   fetch("mockdata/" + areacode + ".json").then(e => e.json()).then(json => this.registerMapAndData(areacode, json))

  }
 },
 watch: {
  hideTip(val) {
   // val && this.clamdown()
  }
 }
}
</script>

<style scoped lang="less">
.map-container {
 padding: 20px;
 height: 100%;
 box-sizing: border-box;
 position: relative;

 .back {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  color: #fff;
  cursor: pointer;
 }
}

.fei-xian-tu {
 flex: 1;
 margin-top: 45px;
 height: 700px;
}
</style>

