<template>
 <router-view @resetContaionerSize="resizeCallback" @setBodyBackground="setBodyBackground" @spinScreen="spinScreen" />
</template>

<style lang="less">
body {
 position: relative;
 overflow: hidden;
 margin: 0;
 width: 100vw;
 height: 100vh;
 background-size: 100% 100%;
 background-image: url("@/assets/images/blue-bg.png");
 background-repeat: no-repeat;

 &.usingDarkTheme {
  background-image: url("@/assets/images/dark-bg.png");
 }
}

.spin {
 position: absolute;
 z-index: 1;
 top: 50px;
 left: 50px;
 aspect-ratio: 1;
 width: 45px;

 svg {
  width: 100%;
  height: 100%;
 }
}
</style>

<script>
function resizeCallback() {
 let useColumnLayout = sessionStorage.getItem("useColumnLayout") === "true"
 var w = window.innerWidth
 var h = window.innerHeight
 let scale;
 if (useColumnLayout === true) scale = Math.min(w / 1080, h / 1920)
 else scale = Math.min(w / 1920, h / 1080)
 if (useColumnLayout === true) document.querySelector("body").style.transform = 'rotate(90deg)'
 else document.querySelector("body").style.transform = 'rotate(0deg)'
 document.querySelector(".diy-full-screen-container").style.transform = 'translate( -50%,-50% ) scale(' + scale + ')'
}
window.addEventListener('resize', resizeCallback)
export default {
 mounted() {
  resizeCallback()
 },
 methods: {
  resizeCallback,
  setBodyBackground(usingDarkTheme) {
   document.querySelector("body")
    .classList[usingDarkTheme ? 'add' : "remove"]("usingDarkTheme")
  },
  spinScreen() {
   let device= "PC";

   if (navigator.userAgent.includes("Android")) device = "Phone"
   if (navigator.userAgent.includes("iPhone")) device = "Phone"
   if (device == "Phone") {
    let useColumnLayout = sessionStorage.getItem("useColumnLayout") === 'true'
    sessionStorage.setItem("useColumnLayout", !useColumnLayout)
   }   else {
    document.fullscreenElement ? document.exitFullscreen() : document.querySelector("html").requestFullscreen()
   }
    resizeCallback()
  }
 }
}
</script>
