

<template>
 <div class="lun-bo-biao">
  <dv-border-box-11 title="地区排行榜" :titleWidth="160">


   <div class="lun-bo-biao-container">
    <div style="height:100%">
     <dv-scroll-board :config="lunbobiao" style="height:100%;width: 535px;" @click="rowClick($event)" />

     <!-- 用于预加载assets中的资源 -->
     <div v-show="false">
      <div class="top1" style=" display: grid;height:100%;width:100%;place-content: center;"> <img
        src='@/assets/icon/top1.png' /></div>
      <div class="top2" style=" display: grid;height:100%;width:100%;place-content: center;"> <img
        src='@/assets/icon/top2.png' /></div>
      <div class="top3" style=" display: grid;height:100%;width:100%;place-content: center;"> <img
        src='@/assets/icon/top3.png' /></div>
     </div>

    </div>
   </div>
  </dv-border-box-11>
 </div>
</template>

<script>
export default {
 props: {
  usingDarkTheme: {
   type: Boolean,
   default: false
  },
  dataByMap:Array
 },
 data() {
  return {
   lunbobiao: {
    headerBGC: "transparent",
    oddRowBGC: "transparent",
    evenRowBGC: "#052b5a",
    header: ['排名', '地区', '评分'],
    data: [

    ],
    index: false,
    columnWidth: [166, 166, 166],
    rowNum: 7,
    align: ["center", "center", "center"]
   },
   orgData: [
    { rank: 1, name: '', score: '' },
    { rank: 2, name: '', score: '' },
    { rank: 3, name: '', score: '' },
    { rank: 4, name: '', score: '' },
    { rank: 5, name: '', score: '' },
    { rank: 6, name: '', score: '' },
    { rank: 7, name: '', score: '' }
   ]
  }
 },
 mounted() {
  setTimeout(() => {
   this.lunbobiao = { ...this.lunbobiao, evenRowBGC: this.usingDarkTheme ? "#052b5a" : "#0078ff" }
   this.renderData()
  })
 },
 methods: {
  renderData() {
   

   this.orgData[0].rank = document.querySelector(".top1").outerHTML
   this.orgData[1].rank = document.querySelector(".top2").outerHTML
   this.orgData[2].rank = document.querySelector(".top3").outerHTML

   let data = this.orgData.map(({ rank, name, score }) => ([rank, name, score]))
   this.lunbobiao = { ...this.lunbobiao, data };

  },
  rowClick(ev) {
   this.$emit('rowItemClick', { row: this.orgData[ev.rowIndex] })
  }
 },
 watch: {
  usingDarkTheme(val) {
   this.lunbobiao = { ...this.lunbobiao, evenRowBGC: val ? "#052b5a" : "#0078ff" }
  },
  dataByMap(val){
  if(val.length<=1)return;
  let sorted= JSON.parse(JSON.stringify(val)).sort((a,b)=>b[1]-a[1])
   this.orgData.map((e,index)=>{
     if(sorted[index]){
     e.name=sorted[index][0]
     e.score=sorted[index][1]
     }else{
     e.name=""
     e.score=""
     }
    return e
   })
   this.renderData()

  }
 }
}
</script>

<style scoped lang="less">
.lun-bo-biao {
 flex: 1;
}

.lun-bo-biao-container {
 box-sizing: border-box;
 padding: 70px 30px 30px 30px;
 height: 100%;
}

.usingDarkTheme {
 color: #009CFF;
}</style>