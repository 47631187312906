<template>
 <div class="shu-zi-fan-pai-qi-list">
  <div class="shu-zi-fan-pai-qi-listitem-container">
   <dv-border-box-12>
    <div class="shu-zi-fan-pai-qi" @click="showUserDialog">
     <img src="@/assets/icon/user.png">
     <dv-digital-flop :config="fanpaiqiDataList[0]" style="flex:1;" />
    </div>
   </dv-border-box-12>
  </div>
  <div class="shu-zi-fan-pai-qi-listitem-container" style="margin:0 20px">
   <dv-border-box-12>
    <div class="shu-zi-fan-pai-qi">
     <img src="@/assets/icon/todo.png">
     <dv-digital-flop :config="fanpaiqiDataList[1]" style="flex:1;" />
    </div>
   </dv-border-box-12>
  </div>
  <div class="shu-zi-fan-pai-qi-listitem-container">
   <dv-border-box-12>
    <div class="shu-zi-fan-pai-qi">
     <img src="@/assets/icon/safe.png">
     <dv-digital-flop :config="fanpaiqiDataList[2]" style="flex:1;" />
    </div>
   </dv-border-box-12>
  </div>
 </div>
</template>

<script>
export default {
 data() {
  return {
   fanpaiqiDataList: [
    {
     number: [0],
     content: '{nt}'
    },
    {
     number: [0],
     content: '{nt}'
    },
    {
     number: [0],
     content: '{nt}'
    }
   ]
  }
 },
 mounted(){
  this.fanpaiqiDataList=[
    {
     number: [54232],
     content: '{nt}'
    },
    {
     number: [50],
     content: '{nt}'
    },
    {
     number: [28],
     content: '{nt}'
    }
   ]
 },
 methods: {
  showUserDialog() {
   this.$emit("showUserDialog")
  }
 }
}
</script>

<style scoped lang="less">
.shu-zi-fan-pai-qi-list {
 height: 120px;
 display: flex;
 justify-content: space-between;

}

.shu-zi-fan-pai-qi-listitem-container {
 width: 100%;
 height: 100%;
}

.shu-zi-fan-pai-qi {
 height: 100%;
 display: flex;
 align-items: center;

 img {
  margin-left: 33px;
  width: 70px;
  height: 70px
 }
}</style>