<template>
 <div class="xu-qiu-lei-bie">
  <dv-border-box-11 title="各类质量服务需求市场体量预计（单位：万元）" :titleWidth="450">
   <div class="xu-qiu-lei-bie-container" ref="xu_qiu_lei_bie_echarts"></div>

  </dv-border-box-11>
 </div>
</template>

<script>
export default {
 data() {
  return {
  }
 },
 props: {
  dataByMap: Array
 },
 watch: {
  dataByMap(val) { this.renderechart(val) }
 },
 mounted() {
  this.initechart()
  this.renderechart(this.dataByMap)
  
  this.myChart.on('click',  (ev)=> {
   // console.log(ev.name, ev.data)
   this.$emit("columnclick", ev.name, ev.data)
  })
 },
 methods: {
  initechart() {
   this.myChart = this.$echarts.init(this.$refs.xu_qiu_lei_bie_echarts)
  },
  renderechart(data) {
  let option = {
   /*
   title: {
     text: '各类质量服务需求市场体量预计（单位：万元）',
     textStyle: {
       color: '#fff',
       fontSize: 20
     },
     top: '3%',
     left: '50%',
     textAlign: 'center'
   },
   */
   grid: {
    top: '20%',
    left: '20%',
    right: '10%',
    bottom: '3%'
   },
   tooltip: {
    trigger: 'axis',
    axisPointer: {
     type: 'shadow'
    }
   },
   legend: {
    right: '4%',
    top: '13%',
    textStyle: {
     color: "white"
    }
   },
   grid: {
    top: "20%",
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
   },
   yAxis: {
    type: 'value',
    axisLabel: {

     color: '#ccc'
    }
   },
   xAxis: {
    type: 'category',
    data: ['知识产权', '认证认可', '校准检定', '检验检测'],
    axisTick: false,
    axisLabel: {

     color: 'white'
    }
   },
   series: [
    {
     name: '市场体量',
     type: 'bar',
     data: data[0]
    },
    {
     name: '预估节省费用',
     type: 'bar',
     data: data[1]
    }
   ]
  };
   this.myChart.setOption(option)
  }
 }
}
</script>

<style scoped lang="less">
.xu-qiu-lei-bie {
 flex: 1;
 width: 100%;
}

.xu-qiu-lei-bie-container {
 width: 100%;
 height: 100%;
}
</style>