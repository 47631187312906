<template>
 <div class="xu-qiu-lei-bie">
  <dv-border-box-11 title="按行业分类的质量服务需求量（单位：家）" :titleWidth="430">
   <div class="xu-qiu-lei-bie-container" ref="xu_qiu_lei_bie_echarts"></div>
  </dv-border-box-11>
 </div>
</template>

<script>
export default {
 data() {
  return {
  }
 },
 props: {
  dataByMap: Array
 },
 watch: {
  dataByMap(val) { this.renderechart(val) }
 },
 mounted() {
  this.initechart()
  this.renderechart(this.dataByMap)
  
  this.myChart.on('click',  (ev)=> {
   // console.log(ev.name, ev.data)
   this.$emit("columnclick", ev.name, ev.data)
  })
 },
 methods: {
  initechart() {
   this.myChart = this.$echarts.init(this.$refs.xu_qiu_lei_bie_echarts)
  },
  renderechart(data) {

   let option = {
    /*
     title: {
      text: "按行业分类的质量服务需求量（单位：家）",
      textStyle: {
       color: "#fff",
       fontSize: 24,
      },
      top: "3%",
      left: "50%",
      textAlign: "center"
     },
     */
    yAxis: {
     type: 'category',
     data: ['23525', '国际组织', '社区组织', '电力供应业', '电力供应业', '电力供应业', '社区组织', '电力供应业', '电力供应业', '电力供应业'],
     axisLabel: {
      color: '#ffffff'
     },
     axisLine: {
      show: false,
     },
     axisTick: {
      show: false,
     }
    },
    xAxis: {
     type: 'value',
     axisLabel: {
      color: '#ffffff'
     },
     show: false,
    },
    grid: {
     left: "20%",
     right: "10%",
     bottom: "3%",

    },
    series: [
     {
      data: data,
      type: 'bar',
      itemStyle: {
       // 渐变色设置
       color: "#006aeb"
      },
      label: {
       // 显示柱状图上的数字
       show: true,
       position: 'right',
       color: '#ffffff'  // 将标签颜色设置为白色

      },
     }
    ]
   }
   this.myChart.setOption(option)
  }
 }
}
</script>

<style scoped lang="less">
.xu-qiu-lei-bie {
 flex: 1;
 width: 100%;
}

.xu-qiu-lei-bie-container {
 width: 100%;
 height: 100%;
}
</style>